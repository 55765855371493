/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import ZoomProgramsTemplate from '../../components/templates/ZoomProgramsTemplate';
import { useSelector } from 'react-redux';

const OnlineProgramsPage = () => {
  const programs = useSelector((state) => state?.programsOnlineSlice?.value?.data);
  return (
    <>
      <ZoomProgramsTemplate programs={programs} title={'Programas Online'} />
    </>
  );
};

export default OnlineProgramsPage;
