import React, { useState, useEffect } from 'react';
// import { TiDocumentText } from 'react-icons/ti';
// import { VscFilePdf } from 'react-icons/vsc';
// import { BiCheck } from 'react-icons/bi';
import { useOutletContext } from 'react-router-dom';
import {
  BsFillCloudUploadFill
  //   BsClock,
  //   BsFillFileEarmarkPdfFill,
  //   BsFileEarmarkPptFill,
  //   BsFileEarmarkExcel,
  //   BsFileEarmarkWordFill
} from 'react-icons/bs';
import { FaSpinner } from 'react-icons/fa';
// import { FiImage } from 'react-icons/fi';
import { message, Upload, Button } from 'antd';
import PropTypes from 'prop-types';
// const { Panel } = Collapse;
import _ from 'lodash';
import './index.scss';
import CoursesServices from './../../../Api/courses';
import { updatePresentations } from './../../../redux/ducks/programZoomSlice';
import { useDispatch } from 'react-redux';
import { toBase64 } from './../../../shared/functions/functions';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
// import Moment from 'react-moment';

// const ICONS_COLLAPSE_SIZE = 25;
// const ICONS_COLLAPSE_COLOR = '#ACACAC';

// const { Dragger } = Upload;
// const toBase64 = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });

const BOPresentation = ({ projectId, presentation }) => {
  var myHeaders = new Headers();
  const courseData = useOutletContext();

  const dispatch = useDispatch();
  const [msg, setMsg] = useState(presentation.message);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  myHeaders.append('Content-Type', 'application/json');

  useEffect(() => {
    setFileList(
      _.map(presentation?.attachments, (att) => ({
        uid: att.id,
        name: att.name,
        // status: 'uploading',
        url: att.download_link
        // percent: 100
      }))
    );
    console.log(fileList);
  }, [presentation.attachments]);

  var props = {
    name: 'attachment',
    multiple: true,
    action: `${process.env.REACT_APP_WE_BASE_PATH}v1/users/me/presentation/create`,
    withCredentials: true,
    headers: myHeaders,
    customRequest: async (ev) => {
      console.log(ev);
      if (ev.file.size > 20000000) {
        alert('Los archivos no pueden exceder 20Mb');
        return;
      }
      var file = await toBase64(ev.file);
      var filename = ev.file.name;
      var mimetype = ev.file.type;
      var data = {
        presentation_id: presentation.id,
        file: file.split(',')[1],
        filename,
        mimetype
      };
      setLoading(true);
      var presentations = await CoursesServices.uploadFilePresentation(data);
      dispatch(
        updatePresentations({
          course_id: courseData.id,
          project_id: projectId,
          presentations: presentations.data
        })
      );
      toast.success('El archivo ha sido subido con éxito', {
        icon: false
      });
      setLoading(false);
    },
    onChange(info) {
      const { status } = info.file;
      console.log(projectId);
      console.log(info);
      if (status !== 'uploading') {
        console.log('no uploading');
      } else {
        console.log('Subiendo');
      }

      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onRemove: async (e) => {
      console.log(e);
      var data = { presentation_id: presentation.id, attachment_id: e.uid };
      var presentations = await CoursesServices.deleteFilePresentation(data);
      dispatch(
        updatePresentations({
          course_id: courseData.id,
          project_id: projectId,
          presentations: presentations.data
        })
      );
    },
    onDrop(e) {
      console.log('err', e);
    },
    fileList
  };

  const saveUpdateMessage = async () => {
    var presentations = await CoursesServices.updateMessagePresentation({
      presentation_id: presentation.id,
      message: msg
    });
    dispatch(
      updatePresentations({
        course_id: courseData.id,
        project_id: projectId,
        presentations: presentations.data
      })
    );
  };

  return (
    <div className="presentation">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <label>Mensaje:</label>
      <textarea
        placeholder="Descripción de la presentación o lista de enlaces de documentos"
        rows={4}
        value={msg}
        onChange={(ev) => {
          setMsg(ev.target.value);
        }}
      />
      {presentation.message != msg && (
        <button onClick={saveUpdateMessage} style={{ backgroundColor: '#011754', color: 'white' }}>
          Enviar Mensaje
        </button>
      )}

      <label>Subir Adjuntos:</label>
      <Upload {...props} disabled={loading}>
        <Button className="btn-choose-upload" icon={<BsFillCloudUploadFill />}>
          Seleccionar & Subir (Máx. 20Mb)
        </Button>
        {loading ? <FaSpinner size={20} /> : <></>}
      </Upload>
      {/* {project.title}
      <Dragger className="dragger" {...props}>
        <p className="icons_gap ant-upload-drag-icon">
          <TiDocumentText size={ICONS_COLLAPSE_SIZE} color={ICONS_COLLAPSE_COLOR} />
          <VscFilePdf size={ICONS_COLLAPSE_SIZE} color={ICONS_COLLAPSE_COLOR} />
          <FiImage size={ICONS_COLLAPSE_SIZE} color={ICONS_COLLAPSE_COLOR} />
        </p>
        <p className="ant-upload-text drog_and_drop">Arrastre y suelte sus archivos aquí</p>
        <p className="ant-upload-hint">o</p>
        <button className="btn_upload">Subir un archivo</button>
      </Dragger> */}
    </div>
  );
};
BOPresentation.propTypes = {
  projectId: PropTypes.number,
  presentation: PropTypes.object
};
export default BOPresentation;
