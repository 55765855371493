import React from 'react';
import PropTypes from 'prop-types';
import BOSubtitle from '../../atoms/BOSubtitle';
import Program from '../../molecules/Program';
import _ from 'lodash';
// Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

// import required modules
// import { EffectCoverflow, Pagination } from 'swiper';

import './index.scss';

const Programs = ({ data, title }) => {
  if (title == 'Programas Zoom') {
    var content = [];

    // var parent_courses = ;
    _.forEach(_.uniqBy(data, 'parent_course.name'), (parent) => {
      var els = [];
      var data_show = data?.filter((r) => r?.show);
      _.forEach(data_show, (course, index) => {
        if (parent?.parent_course?.name == course?.parent_course?.name) {
          els.push(<Program key={index} data={course} />);
        }
      });
      content.push(
        <div className="program_container_flex" key={parent?.parent_course?.id}>
          <BOSubtitle id={parent?.parent_course?.id} title={parent?.parent_course?.name} />
          <div className="program_desktop">
            <div className="programs_grid">{els}</div>
          </div>
        </div>
      );
    });

    return (
      <div className="programs_container">
        {data?.length > 0 && content}
        {data?.length == 0 && (
          <>
            <div className="program_container_flex">
              <div className="program_without_results ">
                <span>Aún no tienes cursos disponibles.</span>
              </div>
            </div>
          </>
        )}
      </div>
    );
  } else {
    var content_online = [];

    // var parent_courses = ;
    _.forEach(_.uniqBy(data, 'parent_course.name'), (parent) => {
      var els = [];
      var data_show = data?.filter((r) => r?.show);
      _.forEach(data_show, (course, index) => {
        if (parent?.parent_course?.name == course?.parent_course?.name) {
          els.push(<Program key={index} data={course} />);
        }
      });
      content_online.push(
        <div className="program_container_flex" key={parent?.parent_course?.id}>
          <BOSubtitle id={parent?.parent_course?.id} title={parent?.parent_course?.name} />
          <div className="program_desktop">
            <div className="programs_grid">{els}</div>
          </div>
        </div>
      );
    });

    return (
      <div className="programs_container">
        {data?.length > 0 && content_online}
        {data?.length == 0 && (
          <>
            <div className="program_container_flex">
              <div className="program_without_results ">
                <span>Aún no tienes cursos disponibles.</span>
              </div>
            </div>
          </>
        )}
      </div>
    );
    // return (
    //   <div className="programs_container">
    //     {data?.length > 0 && (
    //       <div className="program_container_flex">
    //         <BOSubtitle
    //           id={data?.id}
    //           title={`Total: ${data?.filter((el) => el?.show).length} ${title}`}
    //         />
    //         <div className="program_desktop">
    //           <div className="programs_grid">
    //             {data
    //               ?.filter((r) => r?.show)
    //               .map((e, index) => (
    //                 <Program key={index} data={e} />
    //               ))}
    //           </div>
    //         </div>
    //       </div>
    //     )}
    //     {data?.length == 0 && (
    //       <>
    //         <div className="program_container_flex">
    //           <div className="program_without_results ">
    //             <span>Aún no tienes cursos disponibles.</span>
    //           </div>
    //         </div>
    //       </>
    //     )}
    //   </div>
    // );
  }
};

Programs.propTypes = {
  data: PropTypes.array,
  title: PropTypes.any
};

export default Programs;
