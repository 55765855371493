import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

export const programsZoomSlice = createSlice({
  name: 'programsZoom',
  initialState: {
    value: { data: [] }
  },
  reducers: {
    setProgramsZoom: (state, action) => {
      // console.log(action);
      if (state.value?.data === undefined || state.value?.data === []) {
        state.value = { data: action?.payload?.data.map((el) => ({ ...el, show: true })) };
      } else {
        var courses = _.map(action?.payload?.data, (course) => {
          for (var i = 0; i <= state?.value?.data?.length; i++) {
            if (course?.id == state?.value?.data[i]?.id) {
              return { ...state?.value?.data[i], ...course };
            } else {
              return { ...course, show: true };
            }
          }
        });
        state.value = { data: courses };
      }
    },
    updateProgramZoom: (state, action) => {
      // console.log('updateProgramZoom');
      var data = _.clone(state.value.data);
      var course = action?.payload?.course;
      var result = [];
      if (_.find(data, (el) => el?.id == course?.id)) {
        result = _.map(data, (el) => {
          if (el?.id == course?.id) {
            return _.assignIn(el, course);
          }
          return el;
        });
      } else if (state.value['data'].length == 0) {
        result = [action?.payload?.course];
      } else {
        result = state.value.data;
      }
      state.value['data'] = result;
    },
    searchProgramsZoom: (state, action) => {
      var data = _.clone(state.value.data);
      console.log(action);
      console.log(data);
      var show;
      data = data.map((el) => {
        show = true;
        if (
          el?.name.toLowerCase().indexOf(action.payload?.keyword?.toLowerCase()) == -1 &&
          el?.name.length >= 3
        ) {
          show = false;
        }
        return { ...el, show };
      });
      state.value['data'] = data;
    },
    updatePresentations: (state, action) => {
      var data = _.clone(state.value.data);
      var course_id = action.payload.course_id;
      var project_id = action.payload.project_id;
      var result = _.map(data, (course) => {
        if (course.id == course_id) {
          _.map(course.projects, (project) => {
            if (project.id == project_id) {
              project.my_presentations = action.payload.presentations;
            }
          });
        }
        return course;
      });
      state.value['data'] = result;
    }
  }
});

export const {
  setProgramsZoom,
  getProgramsZoom,
  searchProgramsZoom,
  updatePresentations,
  updateProgramZoom
} = programsZoomSlice.actions;

export default programsZoomSlice.reducer;
