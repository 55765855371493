/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { useOutletContext } from 'react-router-dom';
import {
  BsPlayCircleFill,
  BsCloudDownloadFill,
  BsLink45Deg,
  BsFillFileRichtextFill,
  BsArrowUpRightCircleFill
} from 'react-icons/bs';
import _ from 'lodash';
import Moment from 'react-moment';
import 'moment/locale/es';
import { Modal } from 'antd';
import YouTube from 'react-youtube';
import { youtubeParser } from '../../../shared/functions/functions';
// import ReactHtmlParser from 'react-html-parser';
import TitleAndText from '../../molecules/TitleAndText';

import './index.scss';
const ICON_SIZE = 30;
const columns = [
  {
    title: <b>Tipo</b>,
    dataIndex: 'type',
    key: 'type',
    ellipsis: true,
    width: '25px'
  },
  {
    title: <b>Documento</b>,
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
    width: '100px'
  }
  // {
  //   title: 'Última actualización',
  //   dataIndex: 'last_update',
  //   key: 'last_update',
  //   ellipsis: true
  // }

  // {
  //   title: 'Contenido',
  //   dataIndex: 'content',
  //   key: 'content',
  //   ellipsis: true
  // }
];

const ModalInformation = ({ information }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        className="title_information"
        onClick={() => {
          setOpen(true);
        }}>
        <BsFillFileRichtextFill size={ICON_SIZE} />
        <span>{information?.title}</span>
      </div>
      <Modal
        hideBackdrop
        open={open}
        style={{
          top: '60px'
        }}
        width={'80%'}
        onCancel={() => {
          setOpen(false);
        }}
        footer={[]}>
        <div className="modal_body_information">
          <div dangerouslySetInnerHTML={{ __html: information?.content?.value }} />
        </div>
      </Modal>
    </>
  );
};

const CourseAbout = () => {
  const courseData = useOutletContext();
  const [listInformation, setListInformation] = useState([]);
  const [listVideo, setListVideo] = useState([]);

  useEffect(() => {
    setListInformation(
      _.filter(courseData?.list_information, (el) => el.content?.format != 'video')
    );
    setListVideo(_.filter(courseData?.list_information, (el) => el?.content?.format == 'video'));
    console.log(courseData);
  }, [courseData]);

  // console.log(courseData.list_information);
  // var list_information = _.filter((el) => el.content?.format != 'video');
  // var list_video = _.filter(courseData.list_information, (el) => el?.content?.format == 'video');

  var list_information = _.map(listInformation, (el) => ({
    key: el?.id,
    type: (
      <div>
        {el?.type == 'edition' && <span>{'Edición'}</span>}
        {el?.type == 'specific' && <span>{courseData?.name}</span>}
        {el?.type == 'general' && <span>{'General'}</span>}
      </div>
    ),
    title: (
      <>
        {['video', 'file', 'link'].indexOf(el?.content?.format) >= 0 && (
          <div
            className="title_information"
            onClick={() => {
              window.open(el?.content?.value);
            }}>
            {el?.content?.format == 'video' && <BsPlayCircleFill size={ICON_SIZE} />}
            {el?.content?.format == 'file' && <BsCloudDownloadFill size={ICON_SIZE} />}
            {el?.content?.format == 'link' && <BsLink45Deg size={ICON_SIZE} />}
            <span>
              {`${el?.title}  `} <BsArrowUpRightCircleFill />
            </span>
          </div>
        )}
        {el?.content?.format == 'html' && <ModalInformation information={el} />}
      </>
    ),
    last_update: (
      <>
        <Moment format={'DD/MM/YYYY'}>{el?.content?.last_update}</Moment>
        <p>
          <Moment fromNow>{el?.content?.last_update}</Moment>
        </p>
      </>
    ),
    content: <span>{el?.content?.value}</span>
  }));
  return (
    <div className="course_about_container">
      {courseData?.course_type?.indexOf('zoom') == 0 && (
        <>
          <div className="course_videos">
            {_.map(listVideo, (el, idx) => (
              <div key={idx}>
                <YouTube videoId={youtubeParser(el?.content?.value)}></YouTube>
              </div>
            ))}
          </div>
          <div>
            <Table
              columns={columns}
              dataSource={list_information}
              // onChange={handleChange}
              pagination={false}
              // scroll={{ x: 1000 }}
              // width={300}
            />
          </div>
        </>
      )}
      {courseData?.course_type?.indexOf('online') == 0 && (
        <div>
          {courseData?.certification && (
            <TitleAndText title={'Certificación'} description={courseData?.certification} />
          )}
          {courseData?.benefits && (
            <TitleAndText title={'Beneficios'} description={courseData?.benefits} />
          )}
          {courseData?.objectives && (
            <TitleAndText title={'Objetivos'} description={courseData?.objectives} />
          )}
          {courseData?.student_profile && (
            <TitleAndText
              title={'Perfil del estudiante'}
              description={courseData?.student_profile}
            />
          )}
        </div>
      )}

      {/* <Outlet context={courseData} /> */}
    </div>
  );
};

export default CourseAbout;
