import React from 'react';
import PropTypes from 'prop-types';
import BOInput from '../../atoms/BOInput';
import BOSelect from '../../atoms/BOSelect';

import './index.scss';

const academic_degree_opts = [
  'Estudiante Pre – Grado',
  'Egresado',
  'Bachiller',
  'Titulado',
  'Post grado'
];
const career_opts = [
  'Administración',
  'Ingeniería Industrial',
  'Negocios Internacionales',
  'Economía',
  'Contabilidad',
  'Finanzas',
  'Estadística',
  'Marketing',
  'Ingeniería de Sistemas',
  'Ingeniería Informática',
  'Ingeniería Empresarial',
  'Ingeniería Comercial',
  'Ingeniería Agroindustrial',
  'Ingeniería Alimentaria',
  'Ingeniería Ambiental',
  'Ingeniería Civil',
  'Ingeniería de Minas',
  'Ingeniería de Telecomunicaciones',
  'Ingeniería Eléctrica',
  'Ingeniería Electronica',
  'Ingeniería Mecatrónica',
  'Ingeniería Mecánica',
  'Ingeniería Pesquera',
  'Ingeniería Química',
  'Ingeniería Textil',
  'Otra'
];
const position_work_opts = [
  'Practicante Pre-Profesional',
  'Practicante Profesional',
  'Analista',
  'Asistente',
  'Coordinador',
  'Supervisor',
  'Jefe',
  'Gerente',
  'Consultor'
];
const work_area_opts = [
  'Gestión de Procesos',
  'Gestión de Proyectos',
  'Gestión Logística',
  'Análisis de Datos',
  'Gestión Financiera',
  'Comercio Exterior',
  'Contabilidad',
  'Gestión Administrativa',
  'Marketing',
  'Gestión Comercial',
  'Gestión del Talento Humano',
  'Gestión Pública',
  'Producto, Diseño e Innovación',
  'Educación',
  'Producción',
  'Seguridad y Salud en el trabajo',
  'Otro'
];
const sector_opts = [
  'Logística',
  'Transporte y Almacenamiento',
  'Manufactura o Producción',
  'Banca y Seguros',
  'Financiero o Contable',
  'Retail',
  'Comercio',
  'Minería',
  'Telecomunicaciones',
  'Tecnología',
  'Consultora',
  'Servicios Turísticos',
  'Construcción',
  'Energía',
  'Sector Público',
  'Entretenimiento',
  'Educación',
  'Otra'
];
const university_opts = [
  'UPN - Universidad Privada del Norte',
  'UNMSM – Universidad Nacional Mayor de San Marcos',
  'UPC – Universidad Peruana de Ciencia Aplicadas ',
  'UNT – Universidad Nacional de Trujillo',
  'UL – Universidad de Lima',
  'UNI – Universidad Nacional de Ingeniería',
  'PUCP – Pontificia Universidad Católica del Perú',
  'UNFV – Universidad Nacional Federico Villareal',
  'UNAC – Universidad Nacional del Callao ',
  'UTP – Universidad Tecnológica del Perú',
  'ESAN – Universidad ESAN',
  'USIL – Universidad San Ignacio de Loyola',
  'USMP – Universidad San Martin de Porres',
  'UCV – Universidad Cesar Vallejo',
  'UDEP – Universidad de Piura',
  'UP – Universidad del Pacifico',
  'UIGV – Universidad Inca Garcilazo de la Vega',
  'UARM – Universidad Antonio Ruiz de Montoya',
  'UC – Universidad Continental ',
  'URP – Universidad Ricardo Palma',
  'UCSUR – Universidad Científica del Sur',
  'USJB – Universidad Privada San Juan Bautista',
  'UA – Universidad Autónoma del Perú',
  'UPNW – Universidad Privada Norbert Wiener',
  'UAP – Universidad Alas Peruanas',
  'UCSP – Universidad Católica San Pablo ',
  'UNSA – Universidad Nacional San Agustín ',
  'UNASAM – Universidad Nacional Santiago Antúnez de Mayolo',
  'UNPRG – Universidad Nacional Pedro Ruiz Gallo',
  'UNJFSC – Universidad Nacional José Faustino Sánchez Carrión',
  'UPAO – Universidad Privada Antenor Orrego ',
  'UPLA – Universidad Peruana los Andes ',
  'USAT – Universidad Católica Santo Toribio de Mogrovejo',
  'UNC – Universidad Nacional de Cajamarca',
  'UCSM – Universidad Católica de Santa María ',
  'UNCP – Universidad Nacional del Centro del Perú',
  'Universidad San Pedro – Chimbote',
  'UNSLG – Universidad Nacional San Luis Gonzaga de Ica ',
  'UNSAA – Universidad Nacional San Antonio Abad del Cusco ',
  'UNT – Universidad Nacional de Tumbes',
  'UNAP – Universidad Nacional del Altiplano',
  'UTEA – Universidad Tecnológica de los Andes',
  'Universidad de La Salle',
  'UPSP – Universidad Privada San Pedro',
  'Zegel IPAE',
  'IDAT',
  'CIBERTEC',
  'Universidad Nacional Autónoma de México',
  'TECSUP',
  'TELESUP',
  'ISIL',
  'Universidad Central del Ecuador ',
  'Universidad Nacional de Asunción ',
  'Universidad de Chile',
  'Escuela Militar de Ingeniería',
  'Otra'
];

const ProfileAccount = ({ data, errors, profile_field }) => {
  // console.log(data);
  // var { names, surnames, email, country, mobile } = data;
  return (
    <div className="profile_account_container bg-color-areas padding-areas-40 border-radius-30">
      <div className="areas_flex_columns_20">
        <p className="profile_titles text-color-primary">Datos Personales</p>
        <div className="grid_inputs">
          <BOInput
            label="Nombres"
            className="form_item_input"
            value={data?.names}
            onChange={(ev) => {
              profile_field('names', ev.target.value);
            }}
          />
          <BOInput
            label="Apellidos"
            className="form_item_input"
            value={data?.surnames}
            onChange={(ev) => {
              profile_field('surnames', ev.target.value);
            }}
          />
          <BOInput
            label="E-Mail"
            className="form_item_input disable_input"
            value={data?.email}
            onChange={(ev) => {
              profile_field('email', ev.target.value);
            }}
          />
          {/* <BOInput
            label="País"
            className="form_item_input"
            value={data?.country}
            onChange={(ev) => {
              profile_field('country', ev.target.value);
            }}
          /> */}
          <BOInput
            label="Celular"
            className="form_item_input"
            value={data?.mobile}
            onChange={(ev) => {
              profile_field('mobile', ev.target.value);
            }}
          />
          <BOSelect
            label={`Tipo de Documento`}
            className={`form_item_input`}
            value={data?.identification_type}
            options={['DNI', 'OTHER']}
            onChange={(ev) => {
              profile_field('identification_type', ev.target.value);
            }}
          />
          <BOInput
            label={`Número de Documento  ${
              errors?.identification_number ? errors?.identification_number : ''
            }`}
            className="form_item_input"
            value={data?.identification_number}
            onChange={(ev) => {
              profile_field('identification_number', ev.target.value);
            }}
          />
          <BOInput
            label="Fecha de nacimiento"
            type="date"
            className="form_item_input"
            value={data?.birthday}
            onChange={(ev) => {
              profile_field('birthday', ev.target.value);
            }}
          />
        </div>
        <p className="profile_titles text-color-primary">Datos Laborales</p>
        <div className="grid_inputs">
          <BOSelect
            label="Grado académico"
            className="form_item_input"
            value={data?.academic_degree}
            options={academic_degree_opts}
            onChange={(ev) => {
              profile_field('academic_degree', ev.target.value);
            }}
          />
          <BOSelect
            label="Carrera profesional"
            className="form_item_input"
            value={data?.career}
            options={career_opts}
            onChange={(ev) => {
              profile_field('career', ev.target.value);
            }}
          />
          <BOSelect
            label="Universidad"
            className="form_item_input"
            value={data?.university}
            options={university_opts}
            onChange={(ev) => {
              profile_field('university', ev.target.value);
            }}
          />
          <BOSelect
            label="Puesto laboral"
            className="form_item_input"
            value={data?.position_work}
            options={position_work_opts}
            onChange={(ev) => {
              profile_field('position_work', ev.target.value);
            }}
          />
          <BOSelect
            label="Área laboral"
            className="form_item_input"
            value={data?.work_area}
            options={work_area_opts}
            onChange={(ev) => {
              profile_field('work_area', ev.target.value);
            }}
          />
          <BOInput
            label="Empresa"
            className="form_item_input"
            value={data?.company_name}
            onChange={(ev) => {
              profile_field('company_name', ev.target.value);
            }}
          />
          <BOSelect
            label="Sector"
            className="form_item_input"
            value={data?.sector}
            options={sector_opts}
            onChange={(ev) => {
              profile_field('sector', ev.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

ProfileAccount.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  profile_field: PropTypes.func
  // names: PropTypes.string,
  // surnames: PropTypes.string,
  // email: PropTypes.string,
  // country: PropTypes.string,
  // mobile: PropTypes.string
};

// ProfileAccount.defaultProps = {
//   names: 'Nombre de prueba',
//   surnames: 'Apellido de prueba',
//   email: 'correodeprueba@hotmail.com',
//   country: 'Perú',
//   mobile: '+51 999999999'
// };

export default ProfileAccount;
